import React, { FC } from 'react';
import { PageNotFound } from '@buoyhealth/common.buoy-page-not-found';

import Meta from 'components/Meta';

import { RouteMap } from 'constants/Routes';
import useLanguage from 'hooks/useLanguage';
import HttpStatusCodes from 'constants/HttpStatusCodes';

type Props = {
  statusCode?: HttpStatusCodes;
};

const StaticErrorPage: FC<Props> = ({
  statusCode = HttpStatusCodes.NOT_FOUND,
}) => {
  const Language = useLanguage();

  return (
    <div className="StaticErrorPage p-8 py-16 [&__div]:flex [&__div]:items-center [&__div]:justify-center [&__svg]:w-full">
      <Meta renderCanonicalTag={statusCode !== HttpStatusCodes.NOT_FOUND} />
      <PageNotFound
        links={[
          {
            href: RouteMap.HOME.path,
            label: Language.t('StaticErrorPage.homePage'),
          },
          {
            ...(typeof window !== 'undefined'
              ? {
                  href: window.location.href,
                  label: Language.t('StaticErrorPage.refresh'),
                }
              : {}),
          },
        ].filter(Boolean)}
        withBuoyTheme
      />
    </div>
  );
};

export default StaticErrorPage;
