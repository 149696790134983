import React from 'react';
import Head from 'next/head';
import StaticErrorPage from 'components/StaticErrorPage';
import HttpStatusCodes from 'constants/HttpStatusCodes';

const PageNotFound = () => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <StaticErrorPage statusCode={HttpStatusCodes.NOT_FOUND} />
    </>
  );
};

export default PageNotFound;
